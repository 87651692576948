<template>
  <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
    <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
      <!--Заголовок-->
      <b-card-title class="mb-1 fill"> Забыли пароль? </b-card-title>
      <b-card-text class="mb-2">
        Введите свой email, указанный при регистрации и мы отправим Вам
        инструкцию по смене пароля
      </b-card-text>

      <!--Форма-->
      <validation-observer v-slot="{ invalid }">
        <b-form class="auth-forgot-password-form mt-2" @submit.prevent>
          <BlInput
            name="email"
            label="Email"
            rules="required|email"
            placeholder="email@gmail.com"
            input-type="text"
            v-model="email"
          ></BlInput>
          <b-button
            type="submit"
            variant="primary"
            block
            :disabled="invalid"
            @click="forgot()"
          >
            Выслать инструкцию
          </b-button>
        </b-form>
      </validation-observer>

      <!--Карта-->
      <p class="text-center mt-2">
        <b-link :to="{ name: 'login' }">
          <feather-icon icon="ChevronLeftIcon" />
          Назад к входу
        </b-link>
      </p>
    </b-col>
  </b-col>
</template>

<script>
import '@/libs/custom-validate'
import api from '@/api';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BlInput from "@/components/controls/BlInput";

import {
  BCol,
  BLink,
  BCardTitle,
  BCardText,
  BForm,
  BButton,
} from "bootstrap-vue";
import { ValidationObserver } from "vee-validate";

import utils from "@/utils";

export default {
  name: "ForgotForm",
  components: {
    BlInput,
    BCol,
    BLink,
    BForm,
    BButton,
    BCardTitle,
    BCardText,
    ValidationObserver,
  },
  data() {
    return {
      email: "",
      invalid: "",
    };
  },
  computed: {},
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {});
    },
    forgot() {
      const self = this;
      api.auth
        .forgot(this.email)
        .then((response) => {
          if (response.data.message) {
            utils.notificate(self, response.data.message);
          }
        })
        .catch((err) => {
          utils.notificateError(
            self,
            err.response.data.message,
            err.response.data.errorCode
          );
        });
    },
  },
};
</script>

<style scoped>
.fill {
  margin-top: 100px;
}
</style>
