<template>
  <b-col
    lg="8"
    class="d-none d-lg-flex align-items-center p-5"
  >
    <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
    <slot></slot>
    </div>
  </b-col>
</template>

<script>
import {
  BCol,
} from 'bootstrap-vue'

export default {
  name: 'PictureComp',
  components: {
    BCol,
  },
}
</script>

<style scoped>

</style>
