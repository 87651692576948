<template>
  <b-link class="brand-logo">
    <b-img
      src="@/assets/images/logo/logo.svg"
      :width="150"
    />
  </b-link>
</template>

<script>
import { BImg, BLink } from 'bootstrap-vue'

export default {
  name: 'ByLedLogo',
  components: {
    BLink,
    BImg,
  },
}
</script>

<style scoped>

</style>
